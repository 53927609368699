export default {
  created () {
    this.canBack()
  },
  methods: {
    canBack () {
      if (!sessionStorage.getItem('enhedensdige')) {
        console.log('goBKBack方法执行了')
        window.goBKBack.postMessage(0)
      }
    }
  }
}
